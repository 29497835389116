.sticky {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.35);
}

.fr .link-btn {
  color: #778087;
}

.fr .link-btn:hover {
  background-color: #f5f5f5;
  color: #4d5256;
}
