/* Copyright 2021 The casbin Authors. All Rights Reserved.                  */
/* Licensed under the Apache License, Version 2.0 (the "License");          */
/* you may not use this file except in compliance with the License.         */
/* You may obtain a copy of the License at                                  */
/*                                                                          */
/*      http://www.apache.org/licenses/LICENSE-2.0                          */
/*                                                                          */
/* Unless required by applicable law or agreed to in writing, software      */
/* distributed under the License is distributed on an "AS IS" BASIS,        */
/* WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. */
/* See the License for the specific language governing permissions and      */
/* limitations under the License.                                           */

.box-transparent {
  margin-bottom: 20px;
}

.cell-translucent {
  padding: 10px;
  font-size: 14px;
  line-height: 150%;
  text-align: left;
  border-bottom: 1px solid #e2e2e2;
  opacity: 0.8;
  background-color: #ffffff;
  backdrop-filter: blur(4px);
}
