.markdown-body .markdown2-alert {
  position: relative;
  display: inline-flex;
  box-sizing: border-box;
  width: 100%;
  line-height: 1.5;
  padding: 8px 15px 8px 40px;
  margin: 6px 0;
  border-radius: 4px;
  border: 1px solid #eee;
  background-color: #f6f6f6;
  border: none;
}
.markdown-body .markdown2-alert p {
  padding: 0;
  margin: 0;
}
.markdown-body .markdown2-alert-icon {
  font-size: 18px;
  height: 100%;
  position: absolute;
  left: 12px;
  /* top: 50%;margin-top: -10px; //垂直居中; */
  top: 0;
  margin-top: 7.5px;
}
.markdown-body .markdown2-alert-info {
  border-color: #91d5ff;
  background-color: #1890ff20;
}
.markdown-body .markdown2-alert-warning {
  border-color: #ffe58f;
  background-color: #faad1420;
}
.markdown-body .markdown2-alert-success {
  border-color: #b7eb8f;
  background-color: #52c41a20;
}
.markdown-body .markdown2-alert-error {
  border-color: #f5222d;
  background-color: #f5222d20;
}
.markdown-body .markdown2-alert-icon-success {
  color: #52c41a;
}
.markdown-body .markdown2-alert-icon-info {
  color: #1890ff;
}
.markdown-body .markdown2-alert-icon-warning {
  color: #faad14;
}
.markdown-body .markdown2-alert-icon-error {
  color: #f5222d;
}
