/* Copyright 2020 The casbin Authors. All Rights Reserved.                  */
/* Licensed under the Apache License, Version 2.0 (the "License");          */
/* you may not use this file except in compliance with the License.         */
/* You may obtain a copy of the License at                                  */
/*                                                                          */
/*      http://www.apache.org/licenses/LICENSE-2.0                          */
/*                                                                          */
/* Unless required by applicable law or agreed to in writing, software      */
/* distributed under the License is distributed on an "AS IS" BASIS,        */
/* WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. */
/* See the License for the specific language governing permissions and      */
/* limitations under the License.                                           */

.cm-s-node-casbin {
  background: #46483e;
  color: #f8f8f2;
}

.cm-s-node-casbin .CodeMirror-activeline-background {
  background: #3e3d32;
}
