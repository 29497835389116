#logo {
  width: 125px;
  height: 34px;
  /* please replace to your own logo */
  background-image: url("https://cdn.casbin.com/forum/static/img/logo.svg");
  background-size: 125px 34px;
  background-repeat: no-repeat;
  display: inline-block;
}
#logoMobile {
  width: 80px;
  height: 25px;
  /* please replace to your own logo */
  background-image: url("https://cdn.casbin.com/forum/static/img/logo.svg");
  background-size: 80px 25px;
  background-repeat: no-repeat;
  display: inline-block;
}
#logoFooter {
  /* please replace to your own footer logo */
  background-image: url("https://cdn.casbin.com/forum/static/img/logo-footer.png");
  width: 155px;
  height: 39px;
  background-size: 155px 39px;
  background-repeat: no-repeat;
}
a {
  text-decoration: none !important;
  color: inherit;
}
a:link {
  transition: none;
}
#search-container::before {
  content: "";
  position: absolute;
  left: 0;
  top: 1px;
  width: 26px;
  height: 100%;
  transition: filter 0.15s;
  background-image: url("https://cdn.casbin.org/img/search_icon_light.png");
  background-color: transparent;
  background-size: 17px 17px;
  background-position: center;
  background-repeat: no-repeat;
  filter: opacity(40%);
}
.site-nav {
  padding-top: 6px;
  width: 100%;
  display: flex;
  align-items: center;
}
.site-nav .tools {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
#search-result {
  display: none;
  position: absolute;
  background-color: var(--box-background-color);
  top: 38px;
  left: 0;
  width: 100%;
  font-family:
    helvetica neue,
    luxi sans,
    dejavu sans,
    Tahoma,
    hiragino sans gb,
    STHeiti !important;
}
#search-result .search-item.active {
  color: var(--link-primary-color);
  background-color: var(--box-background-hover-color);
}
#search-result .fade {
  display: block;
  margin-bottom: 8px;
}
#search-result .search-item {
  display: block;
  padding: 4px;
  border-radius: var(--box-border-radius);
  color: gray;
}
