/* Copyright 2020 The casbin Authors. All Rights Reserved.                  */
/* Licensed under the Apache License, Version 2.0 (the "License");          */
/* you may not use this file except in compliance with the License.         */
/* You may obtain a copy of the License at                                  */
/*                                                                          */
/*      http://www.apache.org/licenses/LICENSE-2.0                          */
/*                                                                          */
/* Unless required by applicable law or agreed to in writing, software      */
/* distributed under the License is distributed on an "AS IS" BASIS,        */
/* WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. */
/* See the License for the specific language governing permissions and      */
/* limitations under the License.                                           */

.CodeMirror-hints {
  position: absolute;
  z-index: 10;
  overflow: hidden;
  list-style: none;

  padding: 0;
  margin: 0;
  border-radius: 2px;
  border: 1px solid #ccc;
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);

  -webkit-box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.2);

  font-size: 90%;
  font-family: monospace;

  max-height: 20em;
  overflow-y: auto;
}

.CodeMirror-hint {
  border-top: 1px solid #ccc;
  padding: 5px 10px;
  font-size: 14px;
}

.CodeMirror-hint:hover {
  background-color: #f0f0f0;
  cursor: pointer;
  color: #4d5256;
  text-decoration: underline;
}

.CodeMirror-hint:active {
  background-color: #f0f0f0;
  text-decoration: none;
}

li.CodeMirror-hint-active {
  background-color: #f0f0f0;
  text-decoration: none;
}
